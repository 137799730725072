import {Link} from 'react-router-dom';
import {CustomMenu} from '../../../menus/custom-menu';
import {useSettings} from '../../../core/settings/use-settings';

export function AuthLayoutFooter() {
  const {branding} = useSettings();
  return (
    <div className="pt-20 pb-20 text-center gap-30 text-sm text-muted mt-auto">
      <div className="social-icon-main-div">
          <a target="_blank" href="https://www.instagram.com/sayeyo_app/" className="flex-shrink-0 " aria-label='Instagram'>
              <img src="images/insta-icon.png" className="sayeyo-social-icons" alt="Instagram"/>
          </a>
          <a target="_blank" href="https://www.facebook.com/sayeyoapp" className="flex-shrink-0 " aria-label='Facebook'>
              <img src="images/fb-icon.png" className="sayeyo-social-icons" alt="Facebook"/>
          </a>
          <a target="_blank" href="https://www.youtube.com/channel/UCD0wopLvcOx6Fchn3-SO4tw/videos" className="flex-shrink-0 " aria-label='Youtube'>
              <img src="images/youtube-icon.png" className="sayeyo-social-icons" alt="Youtube"/>
          </a>
      </div>
      <Link className="hover:text-fg-base transition-colors copyright-sayeyo" to="/">
        © 2023 sayeYO.com | Download Free!
      </Link>
      <CustomMenu
        menu="auth-page-footer"
        orientation="horizontal"
        itemClassName="hover:text-fg-base transition-colors"
      />
    </div>
  );
}
