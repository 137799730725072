import {Link} from 'react-router-dom';
import {CustomMenu} from '../../../menus/custom-menu';
import {useSettings} from '../../../core/settings/use-settings';
import {useTrans} from '../../../i18n/use-trans';

export function AuthLayoutHeader() {
  return (
    <div className="pb-10 text-sm text-muted mt-auto">
        <div className="app-login-icons">
            <span className="block mb-10 text-md text-center">New user? Create Account</span>
            <a target="_blank" href="https://apps.apple.com/us/app/sayeyo/id1465687740" className="flex-shrink-0 mb-20" aria-label='iOS App'>
                <img src="images/iOSApp.png" className="login-app-links m-auto" alt=""/>
            </a>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.forfamesmash" className="flex-shrink-0 mb-20" aria-label='iOS App'>
                <img src="images/AndroidApp.png" className="login-app-links m-auto" alt=""/>
            </a>
        </div>
    </div>
  );
}
