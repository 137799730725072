import {Link} from 'react-router-dom';
import {ReactNode} from 'react';
import {AuthLayoutFooter} from './auth-layout-footer';
import {AuthLayoutHeader} from './auth-layout-header';
import {useIsDarkMode} from '../../../ui/themes/use-is-dark-mode';
import authBgSvg from './auth-bg.svg';
import {useTrans} from '../../../i18n/use-trans';
import {useSettings} from '../../../core/settings/use-settings';
import {Button} from '../../../ui/buttons/button';
import {Trans} from '../../../i18n/trans';

interface AuthPageProps {
  heading?: ReactNode;
  message?: ReactNode;
  children: ReactNode;
  mainheading?:ReactNode
}
export function AuthLayout({heading, mainheading, children, message}: AuthPageProps) {
  const {branding} = useSettings();
  const isDarkMode = useIsDarkMode();
  const {trans} = useTrans();
  const devicetype = getMobileOperatingSystem();
  let linkType;
  if(devicetype === 'Android'){
    linkType = (
        <a
            href="https://play.google.com/store/apps/details?id=com.forfamesmash"
            className="focus-visible:ring text-on-primary bg-primary undefined border-primary hover:bg-primary-dark hover:border-primary-dark disabled:text-disabled disabled:bg-disabled disabled:border-transparent disabled:shadow-none align-middle whitespace-nowrap inline-flex items-center transition-button duration-200  select-none appearance-none no-underline outline-none disabled:pointer-events-none disabled:cursor-default rounded justify-center font-semibold text-base h-42 px-22 block w-full"
        >
          <Trans message="Open App" />
        </a>
    );
  } else if(devicetype === 'iOS') {
    linkType = (
        <a
            href="https://apps.apple.com/us/app/sayeyo/id1465687740"
            className="focus-visible:ring text-on-primary bg-primary undefined border-primary hover:bg-primary-dark hover:border-primary-dark disabled:text-disabled disabled:bg-disabled disabled:border-transparent disabled:shadow-none align-middle whitespace-nowrap inline-flex items-center transition-button duration-200  select-none appearance-none no-underline outline-none disabled:pointer-events-none disabled:cursor-default rounded justify-center font-semibold text-base h-42 px-22 block w-full"
        >
          <Trans message="Open App" />
        </a>
    );
  }else{
    linkType = (
        <a
            href="https://play.google.com/store/apps/details?id=com.forfamesmash"
            className="focus-visible:ring text-on-primary bg-primary undefined border-primary hover:bg-primary-dark hover:border-primary-dark disabled:text-disabled disabled:bg-disabled disabled:border-transparent disabled:shadow-none align-middle whitespace-nowrap inline-flex items-center transition-button duration-200  select-none appearance-none no-underline outline-none disabled:pointer-events-none disabled:cursor-default rounded justify-center font-semibold text-base h-42 px-22 block w-full"
        >
          <Trans message="Open App" />
        </a>
    );
  }

  return (
      <main
      className="w-full h-full flex flex-col bg-alt dark:bg-none pt-20 px-14 md:px-10vw overflow-y-auto"
    >
        <AuthLayoutHeader />
      <div className="file-grid items-center mobile-display-block">
        <div className="w-1\/2">
          <div className="rounded-login max-w-440 px-40 pt-20 pb-32 w-full mx-auto bg-paper shadow md:shadow-xl">
            <Link
                to="/"
                className="block flex-shrink-0 mb-10"
                aria-label={trans({message: 'Go to homepage'})}
            >
              <img
                  src={isDarkMode ? branding.logo_light : branding?.logo_dark}
            className="block max-h-4rem0 m-auto object-cover"
            alt=""
          />
            </Link>
            {mainheading && <h1 className="mb-30 text-xl text-center login-title-sub">{mainheading}</h1>}
            {heading && <h3 className="mb-10 text-md text-center">{heading}</h3>}
            {children}
          </div>
          {message && <div className="mt-36 text-sm">{message}</div>}
        </div>
        <div className="w-1\/2">
          <img
              src='images/sayeyo-login-image.png'
            className="block m-auto object-cover"
            alt=""
          />
        </div>
        <div className="file-grid items-center px-40 mt-20 openappcss displaynone-desktop">
          <div className="w-1\/2">
            <Trans message="Open sayeYO App" />
          </div>
          <div className="w-1\/2">
            <div className="sticky">
              {linkType}
            </div>
          </div>
        </div>
      </div>
      <AuthLayoutFooter />
    </main>
  );
}

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return "Android";
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return "iOS";
  }
  return "unknown";
}