import {IconButton} from '../../../../ui/buttons/icon-button';
import {openUploadWindow} from '../../../../uploads/utils/open-upload-window';
import {UploadInputType} from '../../../../uploads/types/upload-input-config';
import {AddAPhotoIcon} from '../../../../icons/material/AddAPhoto';
import {Button} from '../../../../ui/buttons/button';
import {UploadedFile} from '../../../../uploads/uploaded-file';
import {Trans} from '../../../../i18n/trans';
import {AvatarPlaceholderIcon} from './avatar-placeholder-icon';

export const MaxAvatarSize = 1572864;

interface AvatarManagerProps {
  className?: string;
  onUpload: (file: UploadedFile) => void;
  onRemove: () => void;
  value?: string;
  isLoading: boolean;
}
export function AvatarManager({
  value,
  className,
}: AvatarManagerProps) {
  return (
    <div className={className}>
      <div className="text-sm mb-18">
        <Trans message="Profile image" />
      </div>
      <div className="w-90 h-90 relative">
        {value ? (
          <img
            src={value}
            className="w-full h-full rounded-full object-cover"
            alt=""
          />
        ) : (
          <AvatarPlaceholderIcon
            viewBox="0 0 48 48"
            className="w-full h-full text-primary/40 bg-primary-light/40 rounded-full"
          />
        )}
      </div>
    </div>
  );
}
